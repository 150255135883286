import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CloseIcon from "@mui/icons-material/Close";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import { Drawer } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BataiyoIcon, BataiyoWhiteLogo } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import { navItems } from "../../constant/staticData";
import { useDialog } from "../../context/DialogContext";
import SignUpForm from "../forms/SignUpForm";
import "./Navbar.css";
const Navbar = () => {
  const { handleDialogOpen } = useDialog();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeHash, setActiveHash] = useState(location.hash);
  useEffect(() => {
    if (location.pathname === "/register") {
      handleDialogOpen();
    }
  }, [location, handleDialogOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const handleNavItemClick = (path) => {
    setIsDrawerOpen(false);
    if (path.startsWith("/#")) {
      const sectionId = path.substring(2);
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.location.href = path;
    }
  };

  const handleDialogOpenMob = () => {
    setIsDrawerOpen(false);
    handleDialogOpen();
  };

  useEffect(() => {
    setActiveHash(location.hash);
  }, [location.hash]);

  const drawerList = (
    <div className="mob-line-icon">
      <div className="mob-nav-heading-box">
        <Link to={ROUTES.HOME} onClick={toggleDrawer(false)}>
          <img
            className="mob-nav-bataiyo-logo"
            src={BataiyoIcon}
            alt="bataiyo"
          />
        </Link>
        <div className="nav-mobile-screen">
          <CloseIcon
            onClick={toggleDrawer(false)}
            className="drawer-white-icon"
          />
        </div>
      </div>
      <div className="mob-nav-list-box">
        <div className="mob-nav-hash-ids">
          {navItems?.map((item) => (
            <React.Fragment key={item?.id}>
              <a
                href={`/${item?.path}`}
                onClick={() => handleNavItemClick(`/${item?.path}`)}
                className={`mob-nav-item-text ${
                  location.pathname === `/${item?.path}` ||
                  activeHash === `${item?.path}`
                    ? "active-nav-link"
                    : ""
                }`}
              >
                {item?.name}
              </a>
            </React.Fragment>
          ))}
          <Link
            className={`mob-nav-item-text ${
              location.pathname === `${ROUTES.BLOG}` && "active-nav-link"
            }`}
            to={ROUTES.BLOG}
          >
            Blogs
          </Link>
          <Link
            className={`mob-nav-item-text ${
              location.pathname === `${ROUTES.SERVICES}` && "active-nav-link"
            }`}
            to={ROUTES.SERVICES}
          >
            Services
          </Link>
        </div>
        <div className="mob-nav-horizontal-line"></div>
        <div className="mob-nav-social-icons-box">
          <div className="mob-nav-follow-text">FOLLOW US:</div>
          <div className="mob-nav-soc-link-box">
            <Link
              to={ROUTES.LINKEDIN_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              LinkedIn <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
            <Link
              to={ROUTES.INSTAGRAM_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              Instagram <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
          </div>
          <div className="mob-nav-soc-link-box">
            <Link
              to={ROUTES.YOUTUBE_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              YouTube <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
            <Link
              to={ROUTES.TWITTER_LINK}
              target="_blank"
              className="mob-nav-soc-link"
            >
              X (Twitter) <ArrowOutwardIcon className="mob-link-blue-arrow" />
            </Link>
          </div>
          <Link
            to={ROUTES.FACEBOOK_LINK}
            target="_blank"
            className="mob-nav-soc-link"
          >
            Facebook <ArrowOutwardIcon className="mob-link-blue-arrow" />
          </Link>
        </div>

        <div className="mobile-sign-up-box">
          <button className="mob-sign-up-button" onClick={handleDialogOpenMob}>
            Register
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <nav className={`navbar ${scrolled ? "scrolled" : ""}`} id="nav">
        <div>
          <a href={`${ROUTES.HOME}#hero`}>
            <img
              src={BataiyoWhiteLogo}
              alt="bataiyo-logo"
              className="bataiyo-logo-img"
              loading="lazy"
            />
          </a>
        </div>
        <div className="nav-mobile-screen">
          <DensityMediumIcon
            onClick={toggleDrawer(true)}
            className="drawer-white-icon"
          />
        </div>
        <div className="nav-items">
          {navItems?.map((item) => (
            <div key={item?.id}>
              <a
                className={`nav-item-text ${
                  location.pathname === `/${item?.path}` ||
                  activeHash === `${item?.path}`
                    ? "active-nav-item-link"
                    : ""
                }`}
                href={`/${item?.path}`}
                onClick={() => handleNavItemClick(`/${item?.path}`)}
              >
                {item?.name}
              </a>
            </div>
          ))}
          <div>
            <Link
              className={`nav-item-text ${
                location.pathname === `${ROUTES.BLOG}`
                  ? "active-nav-item-link"
                  : ""
              }`}
              to={ROUTES.BLOG}
            >
              Blogs
            </Link>
          </div>
          <div>
            <Link
              className={`nav-item-text ${
                location.pathname === `${ROUTES.SERVICES}`
                  ? "active-nav-item-link"
                  : ""
              }`}
              to={ROUTES.SERVICES}
            >
              Services
            </Link>
          </div>
          <button className="sign-up-button" onClick={handleDialogOpen}>
            Register
          </button>
        </div>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "100vh",
              boxSizing: "border-box",
            },
          }}
        >
          {drawerList}
        </Drawer>
      </nav>
      <SignUpForm />
    </>
  );
};

export default memo(Navbar);
