import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { useRef, useState } from "react";
import { AdvantageHeadingicon } from "../../constant/ImagesS3";
import { bataiyoAdvantages } from "../../constant/staticData";
import useCookieConsent from "../../hooks/useCookieConsent";
import AcceptCookiesBanner from "../AcceptCookiesBanner/AcceptCookiesBanner";
import "./TheBataiyoAdvantage.css";
function TheBataiyoAdvantage() {
  const [currentCard, setCurrentCard] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const containerRef = useRef(null);
  const { cookieAccepted, bannerVisible, handleAcceptCookies } =
    useCookieConsent();

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      // Swiped left
      setCurrentCard((prev) =>
        prev === bataiyoAdvantages.length - 1 ? 0 : prev + 1
      );
    }

    if (touchStartX - touchEndX < -50) {
      // Swiped right
      setCurrentCard((prev) =>
        prev === 0 ? bataiyoAdvantages.length - 1 : prev - 1
      );
    }
  };

  const handlePrevCard = () => {
    setCurrentCard((prev) =>
      prev === 0 ? bataiyoAdvantages.length - 1 : prev - 1
    );
  };

  const handleNextCard = () => {
    setCurrentCard((prev) =>
      prev === bataiyoAdvantages.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <section className="adv-main" id="why-bataiyo">
      <div className="adv-text-content">
        <img
          src={AdvantageHeadingicon}
          alt="heading-icon"
          className="heading-icon"
          loading="lazy"
        />
        <h1 className="adv-heading">
          How <span className="heading-color-text"> Bataiyo </span>is Different?
        </h1>
      </div>
      <div className="advantage-container">
        {bataiyoAdvantages?.map((item) => (
          <div className="card-container" key={item?.title}>
            <div className="advantage-image-container">
              <img
                src={item?.img}
                alt={item?.alt}
                className="advantage-card-image"
                loading="lazy"
              />
            </div>
            <div className="card-title">{item?.title}</div>
          </div>
        ))}
      </div>
      <div className="mobile-container">
        <div
          className="mobile-scroll-card"
          ref={containerRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="card-container">
            <div>
              <img
                src={bataiyoAdvantages[currentCard]?.img}
                alt={bataiyoAdvantages[currentCard]?.title}
                className="image-container"
                loading="lazy"
              />
            </div>
            <div className="card-text-content">
              <div className="card-title">
                {bataiyoAdvantages[currentCard]?.title}
              </div>
              <div className="card-description">
                {bataiyoAdvantages[currentCard]?.description}
              </div>
            </div>
          </div>
        </div>
        <div className="slider-arrows">
          <ChevronLeftIcon className="arrow" onClick={handlePrevCard} />
          <ChevronRightIcon className="arrow" onClick={handleNextCard} />
        </div>{" "}
        <div className="slider-dots">
          {bataiyoAdvantages.map((item, index) => (
            <span
              key={index}
              className={`dot ${currentCard === index ? "active" : ""}`}
            ></span>
          ))}
        </div>
      </div>
      {bannerVisible && !cookieAccepted && (
        <AcceptCookiesBanner onAccept={handleAcceptCookies} />
      )}
    </section>
  );
}

export default TheBataiyoAdvantage;
