const blogMainContainer = (isMediumScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: isMediumScreen ? "32px" : "64px",
  padding: isMediumScreen ? "16px" : "64px 96px",
});
const topImageBox = {
  position: "relative",
};
const madeInIndiaStickerImg = (isSmallScreen, isMediumScreen) => ({
  position: "absolute",
  width: isSmallScreen ? "80px" : isMediumScreen ? "150px" : "220px",
  height: isSmallScreen ? "80px" : isMediumScreen ? "150px" : "220px",
  top: isMediumScreen ? "-20px" : "-35px",
  right: isMediumScreen ? "-20px" : "-35px",
  zIndex: "1",
  objectFit: "contain",
});
const topBlogCoverImg = () => ({
  width: "100%",
  height: "100%",
  borderRadius: "12px",
  objectFit: "contain",
  objectPosition: "center",
});
const topBlogCoverMobImg = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
  objectPosition: "center",
};
const topImgTextBox = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "54%",
  transform: "translate(-50%, -50%)",
  flexDirection: "column",
  gap: "6px",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: "2",
};
const topBlogHeadingText = (isSmallScreen, isMediumScreen) => ({
  fontSize: isSmallScreen ? "28px" : isMediumScreen ? "32px" : "54px",
  fontWeight: "800",
  color: "#FFFFFF",
  lineHeight: isSmallScreen ? "32px" : isMediumScreen ? "36px" : "58px",
  margin: "0",
});
const topBlogParaText = (isSmallScreen, isMediumScreen) => ({
  fontSize: isMediumScreen ? "18px" : "20px",
  fontWeight: "500",
  color: "#FFFFFF",
  lineHeight: isMediumScreen ? "24px" : "28px",
  maxWidth: isSmallScreen ? "300px" : "537px",
  margin: "0",
});
const topBlogCardAuthorDateBox = (isSmallScreen) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  gap: isSmallScreen ? "10px" : "20px",
});
const topBlogAuthorImgBox = (isSmallScreen) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: isSmallScreen ? "12px" : "18px",
  color: "#000000",
});
const authorImgStyle = { height: "36px", width: "36px" };
const latestBlogContainer = {
  display: "flex",
  gap: "32px",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  marginBottom: "40px",
};
const latestBlogText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "32px" : "48px",
  lineHeight: isSmallScreen ? "48px" : "60px",
  fontWeight: "700",
  color: "#14171F",
  margin: "0",
});
const latestBlogCard = {
  maxWidth: "100%",
  padding: "16px",
  border: "1px solid #E8E8EA",
  borderRadius: "24px",
  display: "flex",
  gap: "16px",
  flexDirection: "column",
};
const latestBlogLink = { textDecoration: "none" };
const latestBlogGridBox = {
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
};
const latestBlogImage = {
  borderRadius: "6px",
  height: "100%",
};
const cardContentBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  gap: "20px",
  padding: "8px",
};
const cardContentTags = {
  backgroundColor: "#EBF4FF",
  color: "#4B6BFB",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  padding: "4px 10px",
};

const latestBlogAuthorName = {
  width: "100%",
  height: "auto",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: "#7A7A7A",
};
const latestBlogDateStyle = {
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  color: "#7A7A7A",
};
const latestBlogHeadingBox = (isSmallScreen) => ({
  display: "flex",
  flexDirection: isSmallScreen ? "column-reverse" : "row",
  gap: "20px",
  alignItems: "center",
  justifyContent: "center",
});
const viewAllBlogsButton = {
  border: "1px solid #000000",
  color: "#000000",
  padding: "8px 16px",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "20px",
  borderRadius: "100px",
  backgroundColor: "transparent",
};
const viewBlogPostMainContainer = (isMediumScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "32px",
  padding: isMediumScreen ? "16px" : "32px 96px 32px 96px",
});
const viewBlogPostCoverImg = (isMediumScreen) => ({
  width: "100%",
  height: isMediumScreen ? "100%" : "60vh",
  borderRadius: "12px",
  objectFit: "contain",
  objectPosition: "center",
});
const viewBlogPostHeadingBox = (isSmallScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  gap: isSmallScreen ? "10px" : "20px",
});
const backToAllBlogsLink = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "#000",
};
const backArrow = {
  marginRight: "5px",
};
const viewBlogPostTag = {
  padding: "6px 12px",
  borderRadius: "6px",
  backgroundColor: "#0072DD",
  color: "#FFFFFF",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "500",
};
const viewBlogPostTitleText = (isSmallScreen) => ({
  margin: "0",
  color: "#000000",
  fontSize: isSmallScreen ? "24px" : "36px",
  fontWeight: "600",
  lineHeight: isSmallScreen ? "28px" : "40px",
  textAlign: "start",
  width: "100%",
  height: "auto",
});
const viewBlogPostAuthorDateBox = (isSmallScreen) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: isSmallScreen ? "10px" : "24px",
});
const viewBlogPostAuthorBox = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  justifyContent: "space-between",
};
const viewBlogPostAuthorImg = { height: "28px", width: "28px" };
const viewBlogPostAuthorNameText = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  color: "#525252",
};
const viewBlogPostPublishDateText = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#525252",
};
const viewBlogPostDescContainer = { textAlign: "start" };
export const styles = {
  blogMainContainer,
  topImageBox,
  topBlogCoverImg,
  topBlogCoverMobImg,
  madeInIndiaStickerImg,
  topImgTextBox,
  topBlogHeadingText,
  topBlogParaText,
  topBlogCardAuthorDateBox,
  topBlogAuthorImgBox,
  authorImgStyle,
  latestBlogContainer,
  latestBlogText,
  latestBlogGridBox,
  latestBlogCard,
  latestBlogLink,
  latestBlogImage,
  cardContentBox,
  cardContentTags,
  latestBlogAuthorName,
  latestBlogDateStyle,
  latestBlogHeadingBox,
  viewAllBlogsButton,
  viewBlogPostMainContainer,
  viewBlogPostHeadingBox,
  backToAllBlogsLink,
  backArrow,
  viewBlogPostTag,
  viewBlogPostCoverImg,
  viewBlogPostTitleText,
  viewBlogPostAuthorDateBox,
  viewBlogPostAuthorBox,
  viewBlogPostAuthorImg,
  viewBlogPostAuthorNameText,
  viewBlogPostPublishDateText,
  viewBlogPostDescContainer,
};
