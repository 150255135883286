import Lottie from "lottie-react";
import React from "react";
import animationData from "../assets/Animations/six-degree-of-seperation.json";
import "./LottieAnimation.css";

const SixDegreeAnimation = () => {
  return (
    <div className="six-degree-animation-container">
      <Lottie animationData={animationData} loop autoplay />
    </div>
  );
};

export default SixDegreeAnimation;
