import PropTypes from "prop-types";
import { useState } from "react";
import { getFAQSettings } from "../../constant/utils";
import {
  ArrowIconImg,
  containerStyle,
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
  Heading,
} from "./styleSheet";

const FAQAccordion = ({ isUsersQue, isProvidersQue, data }) => {
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };
  const settings = getFAQSettings(isUsersQue, isProvidersQue);
  const { bgColor, color, arrow, questions, heading } = settings;
  return (
    <div style={containerStyle(bgColor)}>
      <Heading variant="h3" color={color}>
        {heading}
      </Heading>
      {data?.map((item, index) => (
        <CustomAccordion
          key={item?._id}
          expanded={expandedPanel === item?._id}
          onChange={handleChange(item?._id)}
          bgColor={bgColor}
        >
          <CustomAccordionSummary
            isLast={index === questions?.length - 1}
            expandIcon={
              <ArrowIconImg
                src={arrow}
                alt="arrow"
                expanded={expandedPanel === item?._id}
                bgColor={bgColor}
              />
            }
            aria-controls={`panel${item?._id}-content`}
            id={`panel${item?._id}-header`}
          >
            {item?.question}
          </CustomAccordionSummary>
          <CustomAccordionDetails>{item?.answer}</CustomAccordionDetails>
        </CustomAccordion>
      ))}
    </div>
  );
};

FAQAccordion.propTypes = {
  isUsersQue: PropTypes.bool,
  isProvidersQue: PropTypes.bool,
  data: PropTypes.array,
};

export default FAQAccordion;
