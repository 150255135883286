const formControlWrapper = {
  width: "100%",
};
const inputContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  width: "100%",
  margin: "0",
};
const inputBoxStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "13px !important",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "18px",
    fontFamily: "Outfit",
    color: "#525252",
  },
  "& .MuiInputAdornment-root": {
    margin: "0 -2px 0 0",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "18px",
    fontFamily: "Outfit",
    color: "#525252",
  },
};
const autocompleteBoxStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    padding: "0px !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "13px !important",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "18px",
    fontFamily: "Outfit",
    color: "#525252",
  },
};
const googleAutocompleteBoxStyle = (locationError) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    padding: "0px !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "13px !important",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "18px",
    fontFamily: "Outfit",
    color: "#525252",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: locationError ? "#d32f2f" : "#0000003b",
  },
});
const formLabel = {
  fontSize: "14px",
  lineHeight: "16px",
  fontFamily: "Outfit",
  color: "#525252",
};
const radioContainer = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};
const radioOptionsLabel = {
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
    fontFamily: "Outfit",
    color: "#292929",
  },
};
const RadioGroupBox = { flexDirection: "row" };
export const FormStyles = {
  autocompletePaper: {
    maxHeight: 500,
    overflowY: "auto",
    backgroundColor: "#fbfbfb !important",
    "& > *": {
      maxHeight: 250,
      fontWeight: 400,
      fontSize: "1rem",
      fontFamily: "Outfit",
      color: "#525252",
    },
  },
};
const confirmationBox = (isSmallScreen) => ({
  gap: isSmallScreen ? "8px" : "24px",
});
const confirmationLabel = {
  display: "flex",
  justifyContent: "space-between",
  gap: "12px",
  alignItems: "start",
  padding: "0",
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Outfit",
    color: "#000000",
  },
};
const phoneNumFlagImage = { width: 24, height: 16, marginRight: 8 };
const dropDownStyle = { marginRight: 25, cursor: "pointer" };
const googlePlacesInput = {
  "& .MuiAutocomplete-endAdornment": {
    right: "9px",
    backgroundColor: "white",
  },
};
const errorMessagesStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "5px",
  fontFamily: "Outfit",
  fontSize: "14px",
  color: "#d32f2f",
  margin: "3px 0 0 6px",
};
export const styles = {
  formControlWrapper,
  inputContainer,
  inputBoxStyle,
  autocompleteBoxStyle,
  formLabel,
  radioContainer,
  radioOptionsLabel,
  RadioGroupBox,
  confirmationBox,
  confirmationLabel,
  phoneNumFlagImage,
  dropDownStyle,
  googlePlacesInput,
  googleAutocompleteBoxStyle,
  errorMessagesStyle,
};
