import React from "react";
import Marquee from "react-fast-marquee";
import { IndiaFlag } from "../../constant/ImagesS3";
import "./HeroSection.css";

function RegistrationMarquee() {
  const marqueeContent = (
    <div className="hero-values-box">
      <img src={IndiaFlag} alt="flag" className="india-flag-value-img" />
      <div className="hero-values-text">
        Registrations started,{" "}
        <span className="hero-values-span">Sign Up Now</span>
      </div>
    </div>
  );
  const repeatCount = 25;

  return (
    <section className="hero-logo-section-container">
      <Marquee direction="left" speed={75} className="marquee-top">
        {Array.from({ length: repeatCount }).map((_, index) => (
          <React.Fragment key={index}>{marqueeContent}</React.Fragment>
        ))}{" "}
      </Marquee>
    </section>
  );
}

export default RegistrationMarquee;
