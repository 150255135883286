export const ROUTES = {
  HOME: "/",
  PRIVACY_POLICY: "/privacy",
  TERMS: "/tnc",
  BLOG: "/blog",
  DISCLAIMER: "/disclaimer",
  COOKIES_POLICY: "/cookies-policy",
  DELETE_ACCOUNT: "/delete",
  CHANGE_LANGUAGE: "/change-language",
  ABOUT: "/about",
  CONTACT: "/contact",
  SUBSCRIBE: "/subscribe",
  FAQ: "/getwebfaq",
  SIX_DEGREE_SEPERATION_WIKI_URL:
    "https://en.wikipedia.org/wiki/Six_degrees_of_separation",
  GET_LOCATION: "/location?input=",
  SERVICES: "/services",
  SERVICESMAP: "servicemap",
  CHECK_USER_REGISTERED: "/web/signup/checkUserRegistred",
  sendOTP: "/web/signup/init",
  SIGNUP_FORM: "/web/signUp/complete",
  RECAPTCHA_SITE_KEY: "6LeekCEqAAAAAPJ3C1LZXhwiw6DEg2YR9IyT-Jdi", //production
  // RECAPTCHA_SITE_KEY: "6Lf0dBsqAAAAADIEXbUZRD3gP5ugLZ_fl5sJ4bgc", //testing
  INSTAGRAM_LINK: "https://www.instagram.com/bataiyo.official/",
  FACEBOOK_LINK:
    "https://www.facebook.com/profile.php?id=61560529440864&mibextid=ZbWKwL",
  YOUTUBE_LINK: "https://www.youtube.com/channel/UCh2YhhO5bdamxyC1WSBfcXQ",
  TWITTER_LINK: "https://x.com/bataiyoofficial?t=s9FWulgAI-X1FujT5xYscQ&s=09",
  LINKEDIN_LINK: "https://www.linkedin.com/company/bataiyo/",
  CLOUDFRONT_URL: "https://d2uvzz1jka6usw.cloudfront.net/",
};
