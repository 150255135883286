import React, { useEffect, useRef, useState } from "react";
import { ERROR_MESSAGES } from "../../constant/messages";
import { getFAQs } from "../../services/landingPage.service";
import FAQAccordion from "./FAQAccordion";
import "./FAQSection.css";

function FAQSection() {
  const [faqData, setFaqData] = useState({
    commonFAQsData: [],
    usersFAQsData: [],
    providerFAQsData: [],
  });
  const [error, setError] = useState(false);
  const faqRef = useRef(null);
  const [isFAQVisible, setIsFAQVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsFAQVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = faqRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [faqRef]);
  useEffect(() => {
    if (!isFAQVisible) return;

    const getData = async () => {
      try {
        const response = await getFAQs();
        const transformedData = {
          commonFAQsData: [],
          usersFAQsData: [],
          providerFAQsData: [],
        };
        response?.data?.result?.forEach((category) => {
          switch (category?.faqCategory) {
            case "Common":
              transformedData.commonFAQsData = category?.qaPairs;
              break;
            case "User":
              transformedData.usersFAQsData = category?.qaPairs;
              break;
            case "Service":
              transformedData.providerFAQsData = category?.qaPairs;
              break;
            default:
              break;
          }
        });

        setFaqData(transformedData);
      } catch (error) {
        setError(true);
        console.error(ERROR_MESSAGES.fetchDataError, error);
      }
    };

    getData();
  }, [isFAQVisible]);

  return (
    <>
      {faqData && !error && (
        <section className="faq-section-container" id="faq" ref={faqRef}>
          <div className="faq-text-content">
            <h2 className="faq-heading">
              Maybe your questions have been answered, check this out.
            </h2>
          </div>
          <FAQAccordion data={faqData?.commonFAQsData} />
          <FAQAccordion isUsersQue={true} data={faqData?.usersFAQsData} />
          <FAQAccordion
            isProvidersQue={true}
            data={faqData?.providerFAQsData}
          />
        </section>
      )}
    </>
  );
}

export default FAQSection;
