import React from "react";
import { OrangeLine } from "../../constant/ImagesS3";
import { sixDegreeSeperationSectionData } from "../../constant/staticData";
import LottieAnimation from "../../LottieAnimation/SixDegreeAnimation";
import "./SixDegreeSeperationSection.css";
function SixDegreeSeperationSection() {
  return (
    <section className="six-degree-section">
      <div className="idea-content">
        <h1 className="idea-heading">Idea</h1>
        <img
          src={OrangeLine}
          alt="orange-line"
          className="orange-horx-line"
          loading="lazy"
        />
      </div>
      <div className="six-degree-section-container">
        {" "}
        <div className="six-degree-img-container">
          <LottieAnimation />
        </div>
        <div className="six-degree-section-content">
          <h2 className="six-degree-heading">
            {sixDegreeSeperationSectionData?.title}
          </h2>
          {sixDegreeSeperationSectionData?.description?.map((item, index) => (
            <p className="six-degree-para" key={index}>
              {item}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
}

export default SixDegreeSeperationSection;
