import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AllBlogsImg,
  AllBlogsMobImg,
  MadeInIndiaSticker,
} from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import { blogsListData } from "../../constant/staticData";
import { getInitialChar, truncateString } from "../../constant/utils";
import { styles } from "./styleSheet";
function BlogListCard() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1000px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="flag-background-image"
      style={styles.blogMainContainer(isMediumScreen)}
    >
      {/* Top Blog Section */}
      <div style={styles.topImageBox}>
        {isSmallScreen ? (
          <img
            src={AllBlogsMobImg}
            alt="bataiyo-blogs"
            style={styles.topBlogCoverMobImg}
            loading="lazy"
          />
        ) : (
          <>
            <div style={styles.topImgTextBox}>
              <h2
                style={styles.topBlogHeadingText(isSmallScreen, isMediumScreen)}
              >
                BATAIYO BLOGS
              </h2>
              <p style={styles.topBlogParaText(isSmallScreen, isMediumScreen)}>
                The Impact of Technology on the Workplace: How Technology is
                Changing
              </p>
            </div>
            <img
              src={MadeInIndiaSticker}
              alt="india-sticker"
              style={styles.madeInIndiaStickerImg(
                isSmallScreen,
                isMediumScreen
              )}
              loading="lazy"
            />
            <img
              src={AllBlogsImg}
              alt="bataiyo-blogs"
              style={styles.topBlogCoverImg(isSmallScreen)}
              loading="lazy"
            />
          </>
        )}
      </div>

      {/* Latest Blogs Section */}
      <div style={styles.latestBlogContainer}>
        <div style={styles.latestBlogHeadingBox(isSmallScreen)}>
          <h3 style={styles.latestBlogText(isSmallScreen)}>Latest Blogs</h3>
          {/* <button style={styles.viewAllBlogsButton}>View All Blogs</button> */}
        </div>
        <Grid container spacing={3} justifyContent="start">
          {blogsListData?.map((blog) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={blog?.id}
              sx={styles.latestBlogGridBox}
            >
              <Link
                to={`${ROUTES.BLOG}/${blog.id}`}
                style={styles.latestBlogLink}
              >
                <Card sx={styles.latestBlogCard}>
                  <CardMedia
                    component="img"
                    alt={blog?.title}
                    height="240px"
                    width="360px"
                    sx={styles.latestBlogImage}
                    image={blog?.coverImage}
                  />
                  <CardContent sx={styles.cardContentBox}>
                    <Box sx={styles.cardContentTags}>{blog?.tags}</Box>
                    <Box className="blog-title">
                      {" "}
                      {truncateString(blog?.title, 80)}
                    </Box>
                    <Box sx={styles.topBlogCardAuthorDateBox(isSmallScreen)}>
                      <Box sx={styles.topBlogAuthorImgBox(isSmallScreen)}>
                        {blog?.authorImage ? (
                          <img
                            src={blog?.authorImage}
                            alt="author-icon"
                            style={styles.authorImgStyle}
                            loading="lazy"
                          />
                        ) : (
                          getInitialChar(blog?.authorName)
                        )}
                        <Box sx={styles.latestBlogAuthorName}>
                          {blog?.authorName}
                        </Box>
                      </Box>
                      <Box sx={styles.latestBlogDateStyle}>
                        {blog?.publishDate}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default BlogListCard;
